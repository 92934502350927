import { vis_compare, vis_unitVideo }           from 'state/store.global'
import { vis_share }             from 'state/store.global'
import { vis_calculate_benefit } from 'state/store.global'
import { useAtom }               from 'state/jotai'
import { ComparePortal }         from 'components/Compare/Compare.Portal'
import { SharePortal }           from 'components/Share/Share.Portal'
import { BenefitPortal }         from 'components/HousingBenefit/HousingBenefit'
import { VideoPortal } from 'components/UnitVideoContainer/VideoPortal'

export const Portals = () => {

  const [comp, setComp]   = useAtom(vis_compare)
  const [share, setShare] = useAtom(vis_share)
  const [bene, setBene]   = useAtom(vis_calculate_benefit)
  const [videoStart, setVideoStart] = useAtom(vis_unitVideo)

  return (
    <>
    { comp  && <ComparePortal close={()=>setComp(false)}/> }
    { share && <SharePortal close={()=>setShare(false)}/>  }
    { bene  && <BenefitPortal close={()=>setBene(false)}/> }
    { videoStart.open  && <VideoPortal close={()=>setVideoStart({open: false, inital_video: 0, singleVideo: false})}/> }
    </>
  )

}
