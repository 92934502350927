const palette = {
  primary: {
    main: "#fa9cda",
    contastText: "#3e342a",
  },
  secondary: {
    main: "#506e5c",
    contastText: "#f4f1e0",
  },
  text: {
    primary: "#000000",
  },
  success: {
    main: "#0E912C",
  },
  common: {
    black: "#3e342a",
    white: "#f4f1e0",
  },
};

export default palette;
