import { Box, styled } from "@mui/material";
import { Fullscreen } from "components/Fullscreen/Fullscreen";
import { Icon } from "components/Icons";
import { ImgWrapper } from "components/ImgContainer/ImgWrapper";
import { MaximizedGallery } from "components/MaximizedGallery/MaximizedGallery";
import Video from "components/Video/Video";
import { useBreak } from "hooks/useBreak";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useAtom } from "state/jotai";
import { _fullscreen } from "state/store.global";
import { Controller, FreeMode, Keyboard, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Swiper, SwiperSlide } from "swiper/react";

function Gallery({ images,thumbImg = [], hideThumbs, fullHeight, UnitDrawer ,height, Unit, onClick, playBtnHidden, hideVideo}) {
  if(!Unit) return null
  // console.log(images,"images",thumbImg,"thumb")
  const [, setIsFullscr] = useAtom(_fullscreen);
  const [activeIndex, setActiveIndex] = useState(0);
  const imageUrls = images.map((i) => i?.url);
  
  const thumnUrls = thumbImg.map((i) => i?.url);

  const video       = Unit.getVideos();
  let videosImages = [...imageUrls]
  
  if(!hideVideo) {
    videosImages = [...videosImages, ...video?.map(v => ({url: v, type: 'video'}))]
  } // pushing video url to last position into images array urls


  
  const showFullScreen = (ind) => {
    setActiveIndex(ind);
    setIsFullscr(true);
  };

  return (
    <>
      <GallerySwiper
        imagesLength={imageUrls?.length}
        images={videosImages}
        activeIndex={activeIndex}
        onClickImage={showFullScreen}
        hideThumbs={hideThumbs}
        fullHeight={fullHeight}
        UnitDrawer={UnitDrawer}
        thumbnails={thumnUrls}
        height={height}
        Unit={Unit}
        onClick={onClick}
        playBtnHidden={playBtnHidden}
      />
      <Fullscreen>
        <MaximizedGallery imagesLength={imageUrls?.length} onClick={onClick} initial={activeIndex} images={[...images, ...video?.map(v => ({url: v, type: 'video'}))]} />
      </Fullscreen>
    </>
  );
}

Gallery.propTypes = {
  images: PropTypes.array,
  hideThumbs: PropTypes.bool,
  fullHeight: PropTypes.bool,
};

Gallery.defaultProps = {
  images: [],
};

function GallerySwiper({
  images,
  thumbnails,
  activeIndex,
  onClickImage,
  hideThumbs,
  fullHeight,
  UnitDrawer,
  height="100%",
  onClick,
  playBtnHidden,
  Unit,
  imagesLength

}) {
 
  const [swiper, setSwiper] = useState(null);
  const [swiperThumbs, setSwiperThumbs] = useState(null);
  const isMobile = useBreak('md_up')

  const handlePrev = () => {
    swiper.slidePrev();
  };

  const handleNext = () => {
    swiper.slideNext();
  };

  const handleClickImage = (ind) => {
    onClickImage(ind);
  };

  const handleClickThumb = (ind) => {
    swiper.slideTo(ind);
  };

  return (
    <Box
      sx={{
        userSelect: "none",
        "& .swiper-slide": {
          cursor: "pointer",
        },
        "& .swiper-slide > img": {
          display: "block",
        },
        height: fullHeight ? "100%" : height,
        "& > .swiper": {
          height: fullHeight ? "100%" : "auto",
        },
        "& > .swiper .swiper-slide > img": {
          width: "100%",
          height: fullHeight ? "100%" : !isMobile ? "100%" : "auto",
          objectFit: "cover",
          // objectFit: "contain",
        },
      }}
    >
      <Swiper
        initialSlide={activeIndex}
        onSwiper={setSwiper}
        controller={{ control: swiperThumbs }}
        modules={[FreeMode, Keyboard, Thumbs, Controller]}
      >
        {images.map((image, i) => {
          return (
          <SwiperSlide key={i} style={{height: fullHeight ? "100%" : isMobile ? "226px" : "252px"}}>
            {
              image?.type === 'video' ? <Video controls={false} onClick={ ()=> onClick({open: true, initial_value: Math.abs(imagesLength - i), singleVideo: true}) } playBtnHidden={playBtnHidden} pointerEvents={true} src={image.url} />
              :
              <ImgWrapper img={image} onClick={() => handleClickImage(i)} />
            }
            {/* <img
              src={image}
              onClick={() => handleClickImage(i)}
              alt=""
              loading="lazy"
            /> */}
          </SwiperSlide>
        )
          })}
        <>
          <Arrow left onClick={handlePrev} UnitDrawer={UnitDrawer}>
            <Icon icon="arrow-left" sx={{ color: "#ffffff", fontSize: 20 }} />
          </Arrow>
          <Arrow right onClick={handleNext} UnitDrawer={UnitDrawer}>
            <Icon icon="arrow-right" sx={{ color: "#ffffff", fontSize: 20 }} />
          </Arrow>
        </>
      </Swiper>

      {/* THUMB */}
      {!hideThumbs && (
        <Box my={1}>
          <Swiper
            initialSlide={activeIndex}
            onSwiper={setSwiperThumbs}
            controller={{ control: swiper }}
            slidesPerView={4.5}
            freeMode={true}
            spaceBetween={5}
            modules={[Thumbs, Controller]}
          >
            {thumbnails.map((image, i) => (
              
              <SwiperSlide key={i} style={{height: isMobile ? "48px" : "54px"}}> 
                {
                  image?.type === 'video' ? <Video controls={false} onClick={ ()=> onClick({open: true, initial_value: Math.abs(imagesLength - i), singleVideo: true}) } playBtnHidden={playBtnHidden} pointerEvents={true} src={image.url} />
                  :
                  <ImgWrapper img={image} onClick={() => handleClickImage(i)} />
                }
                
                {/* <img
                  src={image}
                  alt=""
                  loading="lazy"
                  onClick={() => handleClickThumb(i)}
                /> */}
               
              </SwiperSlide>
             
            ))}
          
          </Swiper>
        </Box>
      )}
    </Box>
  );
}

GallerySwiper.propTypes = {
  images: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  activeIndex: PropTypes.number,
  onClickImage: PropTypes.func,
  hideThumbs: PropTypes.bool,
  fullHeight: PropTypes.bool,
};

GallerySwiper.defaultProps = {
  images: [],
  activeIndex: 0,
  onClickImage: () => {},
};

// STYLES

const Arrow = styled(Box)(({ left, right, UnitDrawer }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  left: left ? 0 : "unset",
  right: right ? 0 : "unset",
  width: 40,
  height: 40,
  backgroundColor: UnitDrawer ? "#000000bf" : "transparent",
  zIndex: 1000,
  cursor: "pointer",
  "& > span": {
    height: 20,
  },
}));

export default Gallery;
