import { Box, Button, IconButton, Modal } from "@mui/material";
import { Icon } from "components/Icons";
import { useAtom } from "jotai";
import React from "react";
import { vis_unitVideo } from "state/store.global";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1444,
  maxWidth: { md: "80%", xs: "100%" },
  boxShadow: "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
  outline: "none"
};

const VideoplayModal = () => {
  const [, setVideoStart] = useAtom(vis_unitVideo)

  const handleOpen = () => setVideoStart({open: true, inital_video: 0});

  return (
    <div>
      <Button
        onClick={handleOpen}
        sx={{
          flexDirection: "column",
          backgroundColor: "rgba(0,0,0,0.8)",
          borderRadius: 0,
          color: "#ffffff",
          py: "8px",
          px: "8px",
          lineHeight: 1,
          fontWeight: 400,
          fontSize: 14,
          "&:hover": {
            backgroundColor: "rgba(0,0,0,1)",
            color: "#ffffff",
          }
        }}
      >
        <Icon icon="eido_video" />
        <Box sx={{ mt: "6px" }}>Video</Box>
      </Button>
    </div>
  );
};

export { VideoplayModal };
2