import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { Box, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { animated, useSpring } from "react-spring";
import { useMeasure } from "react-use";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";

function Description({ header, content, overlayColor }) {
  const [isExpand, setIsExpand] = useState(false);
  const [ref, { height }] = useMeasure();

  const maxHeight = 250;
  const expandable = maxHeight < height;

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const style = useSpring({
    height: isExpand ? height : maxHeight,
    overflow: "hidden",
    config: { duration: 200 },
  });

  const handleToggle = () => {
    setIsExpand((v) => !v);
  };

  return (
    <Box
      sx={{
        position: "relative",
        fontSize: "16px",
        pb: expandable ? "42px" : 0,
        marginRight: { xs: 0, lg: '7rem' }
      }}
    >
      <animated.div style={style}>
        <Box ref={ref}>
          <Typography
            variant="h3"
            sx={{ fontSize: "20px", fontWeight: 700, mb: "24px",color:"#3e342a" }}
          >
            {header}
          </Typography>
          <div style={{color:"#3e342a"}} dangerouslySetInnerHTML={{ __html: content }}></div>
        </Box>
      </animated.div>
      {expandable && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            position: "absolute",
            bottom: -10,
            left: 0,
            width: "100%",
            height: "150px",
          }}
        >
          {!isExpand && (
            <Box
              sx={{
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "100%",
                height: "100%",
                background: `linear-gradient(0deg, ${overlayColor} 40%, ${overlayColor}00 100%)`,
              }}
            ></Box>
          )}
          <Button
            onClick={handleToggle}
            startIcon={isExpand ? <RemoveCircleOutlineOutlinedIcon/> : <AddCircleOutlineOutlinedIcon /> }
            color="inherit"
            sx={{
              fontSize: 16,
               color:"#3e342a",
              ".MuiButton-startIcon > svg": {
                fontSize: 30,
                color:"#3e342a"
              },
            }}
          >
            {isExpand ? buttonsLabel?.filter( btn => btn.UILabelProperty === 'LæsMindre')[0]?.Label : buttonsLabel?.filter( btn => btn.UILabelProperty === 'LæsMere')[0]?.Label }
          </Button>
        </Box>
      )}
    </Box>
  );
}

Description.propTypes = {
  header: PropTypes.string,
  content: PropTypes.string,
  overlayColor: PropTypes.string,
};

Description.defaultProps = {
  header: "",
  content: "",
  overlayColor: "#ffffff",
};

export default Description;
