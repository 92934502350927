import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Icon from "./Icon";

function IconBadge({ badge, ...props }) {
  return (
    <Box component="span" sx={{ position: "relative", display: "inline-flex" }}>
      <Icon {...props} />
      {badge && (
        <Box
          component="span"
          sx={{
            position: "absolute",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: 16,
            height: 16,
            lineHeight: 1,
            fontSize: 10,
            fontWeight: 700,
            backgroundColor: "#7b2c1c",
            color: "#fff",
            top: -3,
            right: -7,
            borderRadius: "50%",
          }}
        >
          {badge}
        </Box>
      )}
    </Box>
  );
}

IconBadge.propTypes = {
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default IconBadge;
