import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { Hotkeys } from 'hotkeys'
import { useSetActiveProject } from 'hooks/useSetActiveProject'
import { useSearchParams } from 'react-router-dom'
import { setProjectData, getProjectData } from 'state/jotai.projectDataHooks'
import { minMax } from 'utilities/utility.minMax'

export const Root = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const sortingOptions = getProjectData('sorteroptions',0,[])
  const setActiveSort = setProjectData('activesorting')
  const asd = getProjectData ('activesorting')

  const sort = searchParams.get('sort')
  const room = searchParams.get('rooms')
  const rooms = parseInt(room) + 1
  const status = searchParams.get('status')
  const size = searchParams.get('size')
  const floor = searchParams.get('floor')
  const rent = searchParams.get('rent') || searchParams.get('Husleje');
  const boligtype = searchParams.get('boligtype')
  const getActiveFilters = getProjectData('activefilters')
  const setActiveFilters = setProjectData('activefilters')

  const sortData = (()=> {
    if(sortingOptions.length && sort) {
      const id = {
        "Address, A - Z": 100,
        "Address, Z - A": 110,
        "Rent, low - high": 120,
        "Rent, high - low": 130,
        "BBR area, low - high": 160,
        "BBR area, high - low": 170,
        "Floor, low - high": 180,
        "Floor, high - low": 190,
        "Unittype, A - Z": 200,
        "Unittype, Z - A": 210,
        "Move in date: First - Last": 220,
        "Move in date: Last - First": 230
      }
      
      const preSorting = sortingOptions.filter((so)=> so.ID === id[sort])

      if(preSorting.length) {
        const sorting = {
          key: preSorting[0].OrderOn,
          direction: preSorting[0].DefaultDirection,
          id: preSorting[0].ID,
          label: preSorting[0].Label,
        }

        return sorting
      }

      return {}
    }
    return {}
  })()
  
  const data = (() => {

    // console.log(options,'options')
    const dropdownOption = {
      ledig: "UnitStatusLedig",
      udlejet: "UnitStatusUdlejet",
      reserveret: "UnitStatusReserveret",
    }
    // const statusParam = status === 'tillgangila'
    const statusParam = status ? true : false
    const roomsParam = rooms ? true : false
    const sizeParam = size ? true : false
    const rentParam = rent ? true : false
    const floorParam = floor ? true : false
    const alterRoomVal = room?.split('-').length === 2 ?  room?.split('-')?.map(r => `${parseInt(r)+1}`).join('-') : room

    const interval = roomsParam ? [{ property: 'VaerelserAntal', min: minMax(alterRoomVal)[0], max: minMax(alterRoomVal)[1] }] : []
    sizeParam && interval.push({ property: 'Areal', min: minMax(size)[0], max: minMax(size)[1] })
    rentParam && interval.push({ property: 'Husleje', min: minMax(rent)[0], max: minMax(rent)[1] })
    floorParam && interval.push({ property: 'Etage', min: minMax(floor)[0], max: minMax(floor)[1] })
    const multiSelect = statusParam ? [{ property: 'Status', values: status.split(',').map(s => dropdownOption[s?.toLocaleLowerCase()]) }] : []
    const dropdown = boligtype ? boligtype : ""
    return  { interval, multiSelect, dropdown }
  })()

  const ready = useSetActiveProject()

  useEffect(() => {
    if (ready) {
      setActiveFilters(data)
      setActiveSort(sortData)
    }
  }, [ready, Object.keys(sortData || {}).length])

  return (
    <>
      <Hotkeys />{' '}
      {/* Hotkeys are added here so that they can access the react router API */}
      <Outlet /> {/* Outlet is where all Views are rendered */}
    </>
  )
}
