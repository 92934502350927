import useSWR, { useSWRConfig } from 'swr'
import { fetcher } from 'utilities/axios.fetchers'

//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
export const useFetch = ({ 

  url  = null,
  sec  = 0,
  file = 'N/A',
  done = () => {},
  setter = null

}) => {
    
  const error_ = (error,key) => { 
    // console.log('━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━')
    // console.log(`⚛!!! swr=ERROR - caller:${file}, key:${key} !!!⚛`) 
    // console.log(error)
    // console.log('━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━')
  }
  
  const success_ = (data, key) => { 
    // console.log(`⚛ swr=DONE - caller:${file} - key:${key}`)
    done(data) 
  }

  const config = {
    revalidateIfStale     : false,
    revalidateOnFocus     : false,
    revalidateOnReconnect : false,
    refreshInterval       : sec ? Number(sec) * 1000 : 0,
    onSuccess             : success_,
    onError               : error_,
  }

  const { data, error, isValidating, mutate } = useSWR( url, fetcher, config ) 
  
  return { D : data, L : isValidating, E : error, mutate}

}
//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━