import { Box, Divider, Stack, useMediaQuery } from "@mui/material";
import { ButtonCompare, ButtonFavorite, ButtonShare } from "components/Unit";
import Unit from "components/Unit/Unit.model";
import PropTypes from "prop-types";
import React from "react";
import { get } from "components/Favorites/Favorites.Utilities";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";

const CompareUnitCard = ({ unit }) => {
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const status = unit.getStatus();
  const floorplan = unit.getFloorplanUrl(true);
  const id = unit.getId();
  const projeknr = `${ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'Bolignr')[0]?.Label } ${unit.getValue("Projeknr")}`;
  const address = unit.getAddress();
  const townAndZipcode = unit.getTownZipCode();
  const price = unit.getPrice();
  const pricePf = unit.isRent() ? `${ get(unit.data,'LejelejlighedsText') }` : "Kontantpris";

  return (
    <Box
      sx={{
        backgroundColor: `${status.color}22`,
      }}
    >
      <Box
        sx={{
          bgcolor: status.color,
          color: "#ffffff",
          fontWeight: 700,
          py: 2,
          px: 2,
        }}
      >
        {status.label}
      </Box>
      <Box>
        <Box
          component="img"
          src={floorplan}
          sx={{
            width: "100%",
          }}
          alt="floorplan"
        />
      </Box>

      <Box
        sx={{
          position: "relative",
          lineHeight: 1.5,
          p: "10px 18px",
          width: "100%",
          flexGrow: 1,
        }}
      >
        <Box sx={{ 
          height: '100px'
        }}>
          <div>{projeknr}</div>
          <div>{address}, {townAndZipcode}</div>
          <Box sx={{ fontWeight: 700 }}>
            {price} {pricePf.toLowerCase()}
          </Box>
        </Box>

        <Divider sx={{ mt: 2, mb: 1 }} />
        {/* actions */}
        <Stack direction="row" justifyContent="space-between">
          <ButtonShare unitId={id} iconOnly={isMobile} />
          <ButtonCompare unitId={id} iconOnly={isMobile} />
          <ButtonFavorite unitId={id} iconOnly={isMobile} />
        </Stack>
      </Box>
    </Box>
  );
};

CompareUnitCard.propTypes = {
  unit: PropTypes.instanceOf(Unit),
};

export default CompareUnitCard;
