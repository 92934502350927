import { Box, Button, IconButton, Modal } from "@mui/material";
import { Icon } from "components/Icons";
import PropTypes from "prop-types";
import React from "react";
import Unit from "./Unit.model";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1444,
  maxWidth: { md: "80%", xs: "100%" },
  boxShadow: "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
  outline: 'none'
};

const FloorplansModalButton = ({ unit }) => {
  const floorplanUrl = unit.getFloorplanUrl();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button
        onClick={handleOpen}
        sx={{
          flexDirection: "column",
          backgroundColor: "rgba(0,0,0,0.8)",
          borderRadius: 0,
          color: "#ffffff",
          py: "8px",
          px: "8px",
          lineHeight: 1,
          fontWeight: 400,
          fontSize: 14,
          "&:hover": {
            backgroundColor: "rgba(0,0,0,1)",
            color: "#ffffff",
          },
        }}
      >
        <Icon icon="floorplan" />
        <Box sx={{ mt: "6px" }}>Plan</Box>
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <IconButton
            onClick={handleClose}
            size="small"
            sx={{
              position: "absolute",
              right: 20,
              top: 20,
              color: "#000000",
              backgroundColor: "rgba(255,255,255,0.8)",
            }}
          >
            <Icon icon="close" fontSize="inherit" />
          </IconButton>
          <Box
            component="img"
            src={floorplanUrl}
            alt=""
            sx={{
              display: "block",
            }}
          />
        </Box>
      </Modal>
    </div>
  );
};

FloorplansModalButton.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

export { FloorplansModalButton };
