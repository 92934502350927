// import ReactPlayer from "react-player";
import styled from 'styled-components/macro'
import { useState } from 'react'
import Spinner from 'react-spinners/BeatLoader'
import { BREAK_ } from 'constants'
import { useAtom } from "jotai";
import { DefaultPlayer as ReactPlayer } from 'react-html5video';
import 'react-html5video/dist/styles.css';

export const Video = ({ src, controls, playBtnHidden, pointerEvents, aspectRatio, onClick }) => {

  const [loading, setLoading] = useState(true)
  
  return (
    <>
      <Section className='player-wrapper' pointerEvents={pointerEvents} playBtnHidden={playBtnHidden} aspectRatio={aspectRatio} onClick={ onClick }>
        {/* <ReactPlayer className='react-player' url={src} width='100%'
        height='100%'
        // light={true}
        playing={false}
        // onStart={()=> setVideoStart(true)}
        controls={controls}
        // playIcon={<h1>hello</h1>}
        /> */}
         <ReactPlayer autoPlay={false} loop muted
            controls={controls ? ['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen'] : controls}
            onCanPlayThrough={() => {
                // Do stuff
            }}>
            <source src={src} type="video/webm" />
            {/* <track label="English" kind="subtitles" srcLang="en" src="http://source.vtt" default /> */}
        </ReactPlayer>
      </Section>
      {/* <div style={{minWidth:10}}></div> */}
    </>
  )
}

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 0.8125rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background: black;

  // &.player-wrapper {
  //   min-height: 200px;
  // }

  &>div{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    pointer-events: ${({ pointerEvents }) => pointerEvents ? 'none' : 'initial'};

    .rh5v-DefaultPlayer_component {
      display: block;
      margin: auto;
      ${ BREAK_.md_up } {
        display: contents;
      }
    }

  }
  
  img {
    height: 100%;
  }

  video {
    background: #000;
    display: block;
    pointer-events: ${({ pointerEvents }) => pointerEvents ? 'none' : 'initial'};
    aspect-ratio: ${({ aspectRatio }) => aspectRatio ? aspectRatio : 'unset'};
    // object-fit: fill;
  }

  div .rh5v-Overlay_inner {
    pointer-events: ${({ pointerEvents }) => pointerEvents ? 'none' : 'initial'};
    display: ${({ playBtnHidden }) => playBtnHidden ? 'none' : 'inline-block'};
  }

  div .rh5v-PlayPause_icon, 
  div .rh5v-Volume_icon,
  div .rh5v-Fullscreen_icon {
    padding: 0px;
  }
`

export default Video;