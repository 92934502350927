import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import Unit from "components/Unit/Unit.model";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomInput, CustomLabel, CustomSelect } from "./styled-components";
import SuccessMessage from "./SuccessMessage";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { _bootstrap } from "state/store.bootstrap";
import { EMAIL, GET_EMAIL_HIT, TESTEMAIL } from "api/api";
import { useSnackbar } from "notistack";
import { useFetch } from "hooks/fetch/useFetch";
import { motion } from "framer-motion";

import { useTheme } from "styled-components";
import { axios_live } from "utilities/axios.instances";
import axios from "axios";
import { useLocation } from "react-router-dom";

const ContactForm = ({ grid, unit, children }) => {
  let location = useLocation();
  const [dropdown_width, setDropdown_width] = useState(300);
  
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const [nationName, setNationName] = useState([]);
  const [describeName, setDescribeName] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [nature, setNature] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const external_home_url = 'https://unityaarhus.com/';
  const { register, handleSubmit, reset, formState: { errors, isValid }, } = useForm({ mode: "onChange", });
  const sitename = useGet(_bootstrap).sitename;
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? [];
  
  const unitNo = unit?.getValue("Projeknr") ? `${buttonsLabel?.find(btn => btn?.UILabelProperty === 'Bolignr')?.Label} ${unit.getValue("Projeknr")},` : "";
  const unitId = unit?.getId();
  const unitAddress = unit?.getAddress() ?? "";

  // const mailNature = useFetch({ url: GET_EMAIL_HIT(pid) });

  const { i18n: { language } } = useTranslation();
  const homeUrl = 'https://estatetool.findapartmentaarhus.unity-living.com';
  const unitUrl = unit?.getUrl(`${homeUrl}/${language}`) ?? "";
  const testLeadData = useFetch({ url: TESTEMAIL(language) });

  const [checked, setChecked] = useState(false);

  const handleCheckbox = (event) => {
    setChecked(event.target.checked);
  };


  let emailArr = ["aarhus.projektsalg@danbolig.dk", "8001@nybolig.dk"];
  // let emailArr = ["akhil.eusteam@gmail.com", "anmol.eusteam@gmail.com"]

  useEffect(()=> {
    testLeadData.mutate()
  }, [language])

  const handleFormSubmit = async (data) => {
  const DescribeIDMapping = {
      'Studerende': '1',
      'Student': '1',
      'Young profesional': '2',
      'Young Professional': '2',
      'Expat': '3',
      'Employee / Fuldtidsansat': '4',
      'B2B': '5',
      'Andet': '6',
      'Other': '6',
      'Personlig': '7',
      'Personal viewing': '7',
      'Digital': '8',
      'Personlig fremvisning': '7',
      'Digital presentation': '8',
      'Digital fremvisining': '8',
      'Traveling Professional': '9',
      'Digital viewing': '8',
      'Tidsbegrænset lejer': '11',
      'Temporary Stay': '11'
  };

  const utms = {
    utm_source: "google",
    utm_medium: "cpc",
    utm_campaign: "spring_sale",
    utm_content: "ad_variation_1"
  }

  let utmParam = {};
  for (let [key, value] of new URLSearchParams(location.search).entries()) {
    if(key === "utm_source" || key === "utm_medium" || key === "utm_campaign" || key === "utm_content") {
      utmParam[key] = value;
    }
  }
  const DANISH_CAMPAIGN = "d1c96c715974a61872276377eb3f6735"
  const ENGLISH_CAMPAIGN = "db8576c3992229ef774aa46793678d74"

  const leadNationality = testLeadData?.D?.countries?.filter((c) => c?.name_dk === data?.nationality_id || c?.name_eng === data?.nationality_id)
  const leadCountry_code = testLeadData?.D?.countries?.filter((c) => c?.country_code === `+${data?.country_id.split("+")?.[1]}`)

        const payload = new URLSearchParams({
          Token: testLeadData?.D?.token,
          Hash: testLeadData?.D?.hash,
          Campaign: language === 'da' ? DANISH_CAMPAIGN : ENGLISH_CAMPAIGN,
          B_ID: unitId,
          Fornavne: data.firstname,
          Efternavn: data.lastname,
          Email_privat: data.email,
          country_code: leadCountry_code?.[0]?.country_id,
          nationality_code: leadNationality?.[0]?.country_id,
          SELECTIONS: DescribeIDMapping[data?.describe_id]+ ",0",
          Telefon_mobil: data.phone,
          Comments: data.kommentar?.length ? data.kommentar : "",
          ...utmParam
        });  
        
      // const emailPayload = {
      //   "ProjectID": pid,
      //   "type": "signup-email",
      //   "front_page_url": external_home_url,
      //   "eido_name": sitename,
      //   "eido_url": homeUrl,
      //   "unit_address": data.bolignummer,
      //   "unit_no": unitId,
      //   "unit_url": unitUrl,
      //   "to_email": emailArr[mailNature?.D?.Nature === 'even' ? 0 : 1],
      //   "form-fields": {
      //     "first_name": data.firstname,
      //     "last_name": data.lastname,
      //     "email": data.email,
      //     "telephone": data.phone,
      //     // "address": data.address,
      //     // "pincode": data.zip,
      //     "country_id": `+${data?.country_id?.split("+")[1]}`,
      //     "nationality_id": data.nationality_id,
      //     "comment": data.kommentar
      //   }
      // };
      
      axios.post('https://aberdeen.estatetool.net/api2/post_leadtool/', payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      // const emailResponse = await axios_live.post(EMAIL(), emailPayload);
      // const variant = emailResponse.data.hasOwnProperty("recipient_email") ? 1 : 0;
      // mailNature.mutate();
      testLeadData.mutate()
      setPersonName([]);
      setNationName([]);
      setDescribeName([])
      reset();
      setChecked(false);
      // setSubmitted(variant);
      // enqueueSnackbar(variant ? language === "da" ? "Emailen er blevet sendt" : "The email has been sent" : language === "da" ? 'Fejl ved indsendelse af formular' : 'Error submitting form', { variant: variant ? 'success' : 'error' });



  };

  const handleNumberInput = (event) => {
    return (event.key.charCodeAt() >= 48 && event.key.charCodeAt() <= 57) || event.key === 'Backspace' ? event.key : event.preventDefault();
  };

  const onSubmit = (data) => {
    handleFormSubmit(data);
  };

  const handleChange = (event) => {
    setPersonName(event.target.value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: !grid ? "right" : "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: !grid ? "right" : "left"
    },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
      },
    },
  };

  const describeOption = {
    'da': [
      "Studerende",
      "Young Professional",
      "Traveling Professional",
      "Expat",
      "B2B",
      "Tidsbegrænset lejer",
      "Andet"
    ],
    'en': [
      "Student",
      "Young Professional",
      "Traveling Professional",
      "Expat",
      "B2B",
      "Temporary Stay",
      "Other",
    ]
  }

  return (
    <>
      {/* {submitted ? (
          <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
             <SuccessMessage />
        </motion.div>
     
      ) : ( */}
        <>
          <Box
            sx={{
              position: 'relative',
            }}
          >
            {children}
            {/* progress */}
            {/* {inProgress && (
              <Box
                sx={{
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 2,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 100,
                    height: 100,
                    backgroundColor: 'rgba(0,0,0,0.25)',
                  }}
                >
                  <CircularProgress />
                </Box>
              </Box>
            )} */}

            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <input
          type="hidden"
          {...register("address")}
          value={`${unitNo} ${unitAddress}`}
        /> */}
              <input
                type='hidden'
                {...register('bolignummer')}
                value={`${unitNo} ${unitAddress}`}
              />
              <input
                type='hidden'
                {...register('source__estate_tool_')}
                value={unitUrl}
              />

              <Grid
                container
                columnSpacing={3}
                rowSpacing={grid ? 2 : 1}
                sx={{ mb: 3 }}
              >
                <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.firstname ? true : false}
                  >
                    <CustomLabel shrink>
                      { buttonsLabel?.filter( btn => btn.UILabelProperty === 'Formularfornavn')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomInput
                      {...register('firstname', { required: true, validate: value => value.trim() !== "" })}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomLabel shrink>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularEfternavn')[0]?.Label } &nbsp;<span>*</span></CustomLabel>
                    <CustomInput {...register('lastname', { required: true, validate: value => value.trim() !== ""  })} />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 12 : 12} xs={12}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.email ? true : false}
                  >
                    <CustomLabel shrink>
                      { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularEmail')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomInput {...register('email', { required: true, validate: value => value.trim() !== ""  })} />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.country_id ? true : false}
                  >
                    <CustomLabel shrink>
                    { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCode')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    
                    <CustomSelect
                        displayEmpty
                        value={personName}
                        onChange={handleChange}
                        onClose={(e) =>  setTimeout(() => {
                          document.activeElement.blur();
                        }, 0)}
                        input={<OutlinedInput {...register('country_id', { required: true, validate: value => value.trim() !== ""  })} placeholder="" />}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <CustomLabel shrink>
                            { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCodeLabel')[0]?.Label } 
                           </CustomLabel>
                          }

                          return selected;
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">
                          <CustomLabel shrink>
                            { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCodeLabel')[0]?.Label } 
                          </CustomLabel>
                        </MenuItem>
                        {testLeadData?.D?.countries?.map((cnty) => (
                           <MenuItem
                           key={`${cnty.country_id} ${cnty.country_code}`}
                           value={`${language === "da" ? cnty?.name_dk : cnty?.name_eng } ${cnty.country_code}`}
                         >
                            {language === "da" ? cnty?.name_dk : cnty?.name_eng } {cnty.country_code} 
                         </MenuItem>
                        ))}
                      </CustomSelect>
                  </FormControl>
                </Grid>
                
                <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.phone ? true : false}
                  >
                    <CustomLabel shrink>
                     { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularTelefon')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomInput type="tel" onKeyDown={handleNumberInput} {...register('phone', { required: true, validate: value => value.trim() !== ""  })} />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.nationality_id ? true : false}
                  >
                    <CustomLabel shrink>
                    {/* { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCode')[0]?.Label }
                     */}
                     
                     { language === 'da' ? 'Nationalitet' : 'Nationality' }
                     &nbsp;<span>*</span>
                    </CustomLabel>
                    
                    <CustomSelect
                        displayEmpty
                        value={nationName}
                        onChange={(event) => setNationName(event.target.value)}
                        onClose={(e) =>  setTimeout(() => {
                          document.activeElement.blur();
                        }, 0)}
                        input={<OutlinedInput {...register('nationality_id', { required: true, validate: value => value.trim() !== ""  })} placeholder="" />}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <CustomLabel shrink>
                            {/* { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCodeLabel')[0]?.Label }  */}
                            { language === 'da' ? 'Vælg nationalitet' : 'Select nationality' }
                           </CustomLabel>
                          }

                          return selected;
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">
                          <CustomLabel shrink>
                            {/* { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCodeLabel')[0]?.Label }  */}
                            { language === 'da' ? 'Vælg nationalitet' : 'Select nationality' }
                          </CustomLabel>
                        </MenuItem>
                        {testLeadData?.D?.countries?.map((cnty) => (
                          <MenuItem
                            key={`${cnty.country_id}`}
                            value={`${language === "da" ? cnty?.name_dk : cnty?.name_eng }`}
                          >
                             {language === "da" ? cnty?.name_dk : cnty?.name_eng }
                          </MenuItem>
                        ))}
                      </CustomSelect>
                  </FormControl>
                </Grid>
                
                <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.describe_id ? true : false}
                  >
                    <CustomLabel shrink>
                    {/* { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCode')[0]?.Label }
                     */}
                     
                     { language === 'da' ? 'Hvad beskriver dig bedst?' : 'What describe you best?' } &nbsp;<span>*</span>
                    </CustomLabel>
                    
                    <CustomSelect
                        displayEmpty
                        value={describeName}
                        onChange={(event) => {
                          setDescribeName(event.target.value)
                        }}
                        onClose={(e) =>  setTimeout(() => {
                          document.activeElement.blur();
                        }, 0)}
                        input={<OutlinedInput {...register('describe_id', { required: true, validate: value => value.trim() !== ""  })} placeholder="" />}
                        renderValue={(selected, i) => {
                          const en = describeOption.en.findIndex((en) => en === selected)
                          const da = describeOption.da.findIndex((da) => da === selected)
                          if (selected.length === 0) {
                            return <CustomLabel shrink>
                            {/* { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCodeLabel')[0]?.Label }  */}
                            { language === 'da' ? 'Vælg venligst' : 'Please choose' }
                           </CustomLabel>
                          }
                          return describeOption?.[language][en > -1 ? en : da];
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">
                          <CustomLabel shrink>
                            {/* { buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCodeLabel')[0]?.Label }  */}
                            { language === 'da' ? 'Vælg venligst' : 'Please choose' }
                          </CustomLabel>
                        </MenuItem>
                        {describeOption?.[language]?.map((desc, index) => (
                          <MenuItem
                            key={`${desc} ${index}`}
                            value={desc}
                          >
                             { desc }
                          </MenuItem>
                        ))}
                      </CustomSelect>
                  </FormControl>
                </Grid>
                {/* <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomLabel shrink>Age</CustomLabel>
                    <CustomInput onKeyDown={handleNumberInput} type="number"  {...register('age')} />
                  </FormControl>
                </Grid> */}

                {/* <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomLabel shrink>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularAdresse')[0]?.Label }</CustomLabel>
                    <CustomInput {...register('address')} />
                  </FormControl>
                </Grid> */}

                {/* <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomLabel shrink>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularPostnummer')[0]?.Label }</CustomLabel>
                    <CustomInput onKeyDown={handleNumberInput} {...register('zip')} />
                  </FormControl>
                </Grid> */}

                <Grid item md={12} xs={12}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomLabel shrink>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularKommentar')[0]?.Label }</CustomLabel>
                    <CustomInput
                      multiline
                      rows={4}
                      {...register('kommentar')}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Box sx={{ mb: 3 }}>
                <CustomLabel>
                  { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularBetingelser')[0]?.Label } &nbsp;<span>*</span>
                </CustomLabel>
                <FormControlLabel
                  sx={{
                    mr: 0,
                    mt: 3
                  }}
                  control={
                    <Checkbox
                      name='_value'
                      checked={checked}
                      onChange={handleCheckbox}
                    />
                  }
                  label={
                    <Box>
                      {/* <div dangerouslySetInnerHTML={{ __html: buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularBetingelserTekst')[0]?.Label }}>
                      </div> */}
                      {
                        language === "da" ?
                        <>
                          Jeg accepterer{' '}
                            <strong>
                              <a 
                                style={{color:clr?.primary_text}}
                                target='_blank'
                                href='https://unity-living.com/da/om-unity/betingelser/'>
                                betingelserne
                              </a>
                            </strong>{' '}
                            og at UNITY samt at projektets mæglere må kontakte mig med information vedr. boliger i UNITY. Jeg kan til enhver tid tilbagekalde mit samtykke.
                        </>
                        : 
                        <>
                         I accept the {' '}
                          <strong>
                            <a 
                              style={{color:clr?.primary_text}}
                              target='_blank'
                              href='https://unity-living.com/en/about-unity/conditions/'>
                              terms and conditions
                            </a>
                          </strong>{' '}
                          and that UNITY and the real estate agents can contact me with information regarding apartments at UNITY. I can withdraw my consent at any time.
                        </>
                      }
                    </Box>
                  }
                />
              </Box>

              <Box
                sx={{
                  textAlign: grid ? 'left' : 'center',
                }}
              >
                <Box>
                  <Button
                    variant='contained'
                    size='large'
                    type='submit'
                    disabled={!checked || !isValid}
                  >
                    Send
                  </Button>
                </Box>
                {/* <Box
                  sx={{
                    my: 3,
                    color: 'success.main',
                  }}
                >
                  <Box sx={{ color: 'success.main' }}>{successMsg}</Box>
                  <Box sx={{ color: 'error.main' }}>{errorMsg}</Box>
                </Box> */}
              </Box>
              {/* <div style={{height:50}}/> */}
            </form>
          </Box>
        </>
      {/* )} */}
    </>
  )
};

ContactForm.propTypes = {
  grid: PropTypes.bool,
  unit: PropTypes.instanceOf(Unit),
  children: PropTypes.node,
};

export default ContactForm;





