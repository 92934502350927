import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function Address({ children }) {
  return (
    <Box
      sx={{
        color:"#3e342a",
        fontSize: 20,
        textAlign: "center",
        fontWeight: 700,
      }}
    >
      {children}
    </Box>
  );
}

Address.propTypes = {
  children: PropTypes.node,
};

export default Address;
