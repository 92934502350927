import styled, {css} from 'styled-components'
import ReactSlider   from 'react-slider'

export const Range = styled(ReactSlider)`
  width: 250px;
  height: 25px;
  cursor: pointer;
  display:flex;
  align-items:center;
  /* border: 1px solid white; */
`
export const Values = styled.div`
  width:250px;
  display:flex;
  position:relative;
  /* border: 1px solid white; */
`
export const Value = styled.div( ({right}) => css`
  height:25px;
  width:25px;
  display:flex;
  justify-content:center;
  line-height:25px;
  text-align:center;
  overflow:visible;
  white-space:nowrap;
  color:#f4f1e0;
  position:absolute;
  transform:${ ( right ) ? 'translatex(50%)' : 'translatex(-50%)' };
  left:${ ( right ) ? 'unset' : '0' };
  right:${ ( right ) ? '0' : 'unset' };
  ${'' /* border: 1px solid white; */}
`)