import { Box, Grow, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, SvgIcon, useMediaQuery, } from '@mui/material'
import { Icon } from 'components/Icons'
import { useRef, useState } from 'react'
import useLanguage from './useLanguage'
import { useTranslation }           from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { _project } from 'state/store.projects'
import { setFocus, useAtom, useGet } from 'state/jotai'
import { current_language, params_query } from 'state/store.global'

function LanguageMenu() {
  const isMobile                             = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const [activeLang, setActiveLang, options] = useLanguage()
  const [anchorEl, setAnchorEl]              = useState(null)
  const anchorRef                            = useRef(null)
  const [isOpen, setIsOpen]                  = useState(false)
  const [, setCurrent_language]              = useAtom(current_language);

  const { i18n, i18n:{language} } = useTranslation()
  const navigateTo                = useNavigate()
  const params                    = useGet(params_query);
  const here                      = useLocation().pathname

  const handleToggle = () => {
    setAnchorEl(anchorRef.current)
    setIsOpen(isOpen => !isOpen)
  }

  const handleClose = () => {
    setIsOpen(false)
    setAnchorEl(null)
  }

  const handleChange = id => {
    setCurrent_language(id)
    setActiveLang(id)
    i18n.changeLanguage(id)
    handleClose()
    navigateTo( here.replace(language,id) + (params.length ? params : '') )  
  }

  return (
    <Box        sx      = {{ display: 'flex', alignItems: 'center', zIndex: 1000, userSelect: 'none', }} ref = {anchorRef} >
    <IconButton onClick = {handleToggle}>
    <Icon       icon    = 'globe' sx={{ color: '#f4f1e0', fontSize: 'inherit' }} />
    <Icon       icon    = 'caretDown' sx                                                                     = {{ position: 'absolute', right: 0, bottom: 2, fontSize: 10, color: '#f4f1e0',transition: 'transform 0.3s', transform: isOpen ? 'rotate(180deg)' : 'rotate(0)', }} ></Icon>
      </IconButton>
      <Menu
        anchorEl            = {anchorEl}
        open                = {isOpen}
        onClose             = {handleClose}
        anchorOrigin        = {{ vertical: 'bottom', horizontal: 'right', }}
        transformOrigin     = {{ vertical: 'top', horizontal: 'right', }}
        anchorReference     = {isMobile ? 'anchorPosition' : 'anchorEl'}
        anchorPosition      = {{ top: 60, left: 400 }}
        PaperProps          = {{ elevation: 1, sx: { width: isMobile ? '100%' : 300, left: isMobile ? '0 !important' : 0, maxWidth: '100%', }, }}
        sx                  = {{ '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.25)', }, '& .MuiPaper-root': { backgroundColor: '#EEECE8F2', borderRadius: 0, }, '& .MuiList-root': { padding: 0, }, }}
        TransitionComponent = {Grow}
      >
        {options.map((item, i) => {
          return (
            <MenuItem
              key = {i}
              sx  = {{
                           color                              : 'text.primary',
                           borderBottom                       : '1px solid rgba(0, 0, 0, 0.15)',
                           padding                            : '1.375rem 2rem',
                           fontSize                           : '1.125rem',
                           backgroundColor                    : 
                activeLang === item.id ? 'rgba(0, 0, 0, 0.05)': 'none',
                           '&:last-child'                     : { borderBottom: 'none' },
                           '& .MuiListItemIcon-root'          : { minWidth: 48 },
              }}
              onClick = {() => handleChange(item.id)}
            >
              <ListItemIcon>
                <SvgIcon sx = {{ fontSize: 34 }}>{item.icon}</SvgIcon>
              </ListItemIcon>
              <ListItemText sx = {{ '& .MuiTypography-root': { fontSize: '1.125rem' ,color: '#3e342a'} }} >
                {item.label}
              </ListItemText>
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  )
}

export default LanguageMenu
