import styled from 'styled-components/macro'
import { sb } from 'styles/styles.scrollbar'
import { BREAK_ } from 'constants'



export const Container = styled.div`
  max-width: 100vw;
  width: 800px;
  height: 400px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  user-select: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
`
export const CloseIcon = styled.div`
  position: absolute;
  filter: invert(0);
  top: 10px;
  right: 10px;
`