import { Box, Divider, Drawer, IconButton, useMediaQuery } from "@mui/material";
import { Icon } from "components/Icons";
import React from "react";
import ContactForm from "./ContactForm";
import { useContactDrawer } from "./useContactDrawer";
import { getFocus, useAtom, useGet } from "state/jotai";
import { _drawer, active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { useBreak } from "hooks/useBreak";

const ContactDrawer = () => {
  const { isOpen, toggle, unit } = useContactDrawer();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
const isDesktop       = useBreak('md_up')
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  const contactData  = getFocus(_project, 'ContactProperties')?.[pin]?.[pid] ?? []
const drawer   = useAtom(_drawer)[0]  
  return (
    <Drawer
      anchor={isMobile ? "bottom" : "right"}
      open={isOpen}
      onClose={() => toggle()}
      PaperProps={{ sx: { scrollbarWidth: "none", "&::-webkit-scrollbar": { display: "none" }, color: "secondary.contastText", backgroundColor: "secondary.main", }, }}
    >
      <Box sx={{ width: isMobile ? "auto" : 422, position: "relative", pt: 4, pb: 4, px: 3, lineHeight: 1.3, }} >
        <IconButton
          onClick={toggle}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
          }}
        >
          <Icon
            icon="close"
            sx={{
              fontSize: 18,
            }}
          />
        </IconButton>

        <Box sx={{ mt: 3, lineHeight: 1.5, }} >
          <Box sx={{ fontWeight: 700, mb: 1 }}>{ buttonsLabel?.filter( btn => btn?.UILabelProperty === 'Kontaktinformation')[0]?.Label }</Box>
          {
            contactData?.map((item, i) => {
              return (
                <Box mb={2} key={i}>
                  { item?.Name } <br />
                  { buttonsLabel?.filter( btn => btn?.UILabelProperty === 'FormularTelefon')[0]?.Label }: { item?.Phone }
                  <br />
                  Email: { item?.Email }
                </Box>
              )
            })
          }
        </Box>
        <Divider sx={{ my: 4, borderColor: "rgba(0,0,0,0.2)", }} />

        <ContactForm unit={unit}>
          <Box sx={{ fontWeight: 700, lineHeight: 1.4, mb: 3, color:isDesktop && drawer? "#f4f1e0" :"#f4f1e0"}} >
            { buttonsLabel?.filter( btn => btn?.UILabelProperty === 'SidefodformularOverskrift')[0]?.Label }<br/><br/>
            <span style={{fontSize:'0.95em',fontWeight:'normal'}}> { buttonsLabel?.filter( btn => btn.UILabelProperty === 'SidefodformularUnderoverskrift')[0]?.Label }</span>
          </Box>
        </ContactForm>
      </Box>
    </Drawer>
  );
};

export default ContactDrawer;
