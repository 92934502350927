import { Box, styled, Tab, Tabs } from "@mui/material";
import PropTypes                  from "prop-types";
import React, { useState }        from "react";
import Address                    from "./Address";
import Buttons                    from "./Buttons";
import CTA                        from "./CTA";
import Description                from "./Description";
import Downloads                  from "./Downloads";
import { FloorplansModalButton }  from "./FloorplansModal";
import Footer                     from "./Footer";
import Gallery                    from "./Gallery";
import Highlights                 from "./Highlights";
import { useUnitHighlight }       from "./hooks";
import OpenHouse                  from "./OpenHouse";
import Price                      from "./Price";
import Properties                 from "./Properties";
import RelatedUnits               from "./RelatedUnits";
import SignUpForm                 from "./SignUpForm";
import Statistics                 from "./Statistics";
import Unit                       from "./Unit.model";
import { get }                    from "components/Favorites/Favorites.Utilities";
import { useGet, getFocus }       from "state/jotai";
import { active_project }         from "state/store.global";
import { _project }               from "state/store.projects";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import { VideoplayModal } from "./VideoplayModal";

const UnitPageMobile = ({ unit }) => {
  const [activeTab, setActiveTab] = useState(0);

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const id         = unit.getId();
  const status     = unit.getStatus();
  const images     = unit.getImages();
  const video      = unit.getVideos();
  const highlights = useUnitHighlight(unit);
  const isRent     = unit.isRent();
  const price      = unit.getPrice();
  const properties = unit.getProperties();

  const descTitle       = unit.getDescriptionTitle();
  const description     = unit.getDescription();
  const areaDescTitle   = unit.getAreaDescriptionTitle();
  const areaDescription = unit.getAreaDescription();

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box
      sx={{
        position: "relative",
        // border: "10px solid blue",
        // pb: 5,
      }}
    >
      <Box
        sx={{
          // p: 1,
          backgroundColor: `${status.color}a1`,
          color          : "#ffffff",
          position       : "absolute",
          zIndex         : 2,
          left           : 0,
          top            : 0,
          padding        : "0.5rem 1rem"
        }}
      >
        {status.label}
      </Box>
      <Box                   sx     = {{ position: "relative" }}>
      <Gallery          Unit={unit}      images = {images.slice(1)} hideThumbs = {true} hideVideo={true} />
      <Box     sx     = {{ position: "absolute", bottom: 0, right: 20, zIndex: 2, display: 'flex', gap: '0.5rem' }}>
          {video?.length > 0 && <VideoplayModal video={video} />}
          <FloorplansModalButton unit = {unit} />
        </Box>
      </Box>
      <Buttons unitId = {id} />
      <OpenHouse />

      <Box sx = {{ px: 2.5, mt: 3 }}>
        <Address>
          <p>{unit.getAddress()},</p>
          <span>{unit.getTownZipCode()}</span>
        </Address>

        <Box        sx    = {{ mt: 3, mx: "auto", maxWidth: 320 }}>
        <Highlights items = {highlights} />
        </Box>

        <Box sx = {{ mt: 2, textAlign: "center" }}>
          <Price
            label={
              isRent ? `${get(unit.data, "LejelejlighedsText")}`: "Kontatpris"
            }
            value = {price}
          />
        </Box>

        <Box sx = {{ mt: 3 }}>
          <CTA />
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#F3F3F3",
          mt             : "40px",
          mb             : "20px",
          padding        : "48px 20px",
          fontSize       : 16,
        }}
      >
        <Properties groups = {properties} indent />

        <Downloads />
      </Box>

      <Box sx = {{ mt: "40px", mb: "20px" }}>
        <StyledTabs
          value    = {activeTab}
          onChange = {handleChange}
          variant  = "fullWidth"
        >
          <StyledTab
            label={`${
              buttonsLabel?.filter(
                (btn) => btn.UILabelProperty === "Boligerne"
              )[0]?.Label
            }`}
          />
          <StyledTab
            label={`${
              buttonsLabel?.filter(
                (btn) => btn.UILabelProperty === "Området"
              )[0]?.Label
            }`}
          />
          {/* <StyledTab
            label={`${
              buttonsLabel?.filter((btn) => btn.UILabelProperty === "Kort")[0]
                ?.Label
            }`}
          /> */}
        </StyledTabs>
      </Box>
      <TabPanel    active = {0 === activeTab} index = {0}>
      <Description header = {descTitle} content     = {description} />
      </TabPanel>
      <TabPanel    active = {1 === activeTab} index = {1}>
      <Description header = {areaDescTitle} content = {areaDescription} />
      </TabPanel>
      <TabPanel active = {2 === activeTab} index = {2}>
      <Box      sx     = {{ height: 400, backgroundColor: "grey" }}></Box>
      </TabPanel>

      <Box        mt   = {4} mb = {4}>
      <Statistics unit = {unit} />
      </Box>

      <Box          mb   = {4}>
      <RelatedUnits unit = {unit} />
      </Box>

      <SignUpForm unit = {unit} />
      <Footer />
      <PoweredBy />
    </Box>
    
  );
};

UnitPageMobile.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

function TabPanel(props) {
  const { children, active } = props;

  return (
    <>
      {active && (
        <Box sx = {{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  active  : PropTypes.bool,
};

const StyledTabs = styled((props) => <Tabs {...props} />)({
  padding               : "0 24px",
  "& .MuiTabs-indicator": {
    display        : "flex",
    justifyContent : "center",
    backgroundColor: "transparent",
  },
});

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform  : "none",
  fontWeight     : 700,
  fontSize       : 16,
  marginRight    : 0,
  color          : "#000000",
  backgroundColor: "#ffffff",
  border         : `1px solid ${theme.palette.primary.main}bf`,
  borderRight    : "none",
  transition     : "all 0.3s",
  "&:last-child" : {
    borderRight: `1px solid ${theme.palette.primary.main}bf`,
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color          : "#fff",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

export default UnitPageMobile;
