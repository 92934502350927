import { Box, Button } from "@mui/material";
import { Icon } from "components/Icons";
import PropTypes from "prop-types";
import React from "react";
import { getFocus, useGet } from "state/jotai";
import { _bootstrap } from "state/store.bootstrap";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";

function DownloadItem({ property, url }) {
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  const sitename = useGet(_bootstrap).sitename;

  return (
    <Box
      sx={{
        backgroundColor: "#f3f3f3",
        borderTop: "1px solid #dbdbdb",
        px: { lg: "32px", xs: "1.125rem" },
        py: "28px",

        "&:last-child": {
          borderBottom: "1px solid #dbdbdb",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            maxWidth: 300,
          }}
        >
          <Box
            sx={{
              fontWeight: 700,
              mb: 1,
            }}
          >
            {
              buttonsLabel?.filter(btn => btn.UILabelProperty === property)[0]
                ?.Label
            }{" "}
            - {sitename.charAt(0).toLowerCase() + sitename.slice(1)}
          </Box>
          <div>
            {buttonsLabel?.filter(
              btn => btn.UILabelProperty === "DownloadPrislistetekst"
            )[0]?.Label ?? ""}
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Button
            variant="link"
            href={url}
            target="_blank"
            sx={{
              fontSize: 17,
              color: "#000000",
              px: 1,
            }}
          >
            <Box
              component="span"
              sx={{
                display: {
                  lg: "inline-block",
                  xs: "none",
                },
                mr: {
                  lg: "30px",
                },
                color:"#3e342a"
              }}
            >
              Download PDF
            </Box>
            <Box
              sx={{
                display: "inline-flex",
                backgroundColor: "primary.main",
                width: 40,
                height: 40,
                borderRadius: "50%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon icon="download" sx={{ fill: "#fff", fontSize: 16 }} />
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

DownloadItem.propTypes = {
  property: PropTypes.string,
  url: PropTypes.string,
};

export default DownloadItem;
