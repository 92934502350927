import { Wrap }     from './Icons.style'
import { Text }     from './Icons.style'
import { Svg }      from './Icons.style'
import { Path }     from './Icons.style'
import { Circle }   from './Icons.style'
import { Relative } from './Icons.style'
import { iconLib }  from './iconslib.jsx'
import { useBreak } from 'hooks/useBreak'

export const Icon = ({
  size      = 25,
  szm       = 15,
  szd       = 25,
  color     = '#fff',
  icon      = 'nav_close',
  className = '',
  style     = {},
  wrap      = {},
  text      = false,
  fontSize  = '0.75rem',
  click     = () => {},
  width     = 'unset',
  count     = false,
  tick      = false,
  hide      = false,
  children
}) => {

  const styles = { ...style }

  const isMobile = useBreak('md_dn')

  const svgAttributes = {
    className: className,
    style: styles,
    viewBox: '0 0 1000 1000',
    width: `${size}px`,
    height: `${size}px`,
  }

  const wrap_ = { onClick: click, width, style: wrap }
  const path_ = { fill: color, d: iconLib[icon] }
  const text_ = { show: text, color, fontSize, children: text }
  
  const tick_ = { show: tick ? true : false, x: size-15, y: -2 }
  const circ_ = { show: count, x: size -12, y: -1 }

  if (hide) return false 
  else return (
    <Wrap {...wrap_}>
      <Relative>
        <Svg {...svgAttributes}>
          <Path {...path_}/>
        </Svg>
        <Circle {...circ_}>{count}</Circle>
        <Circle {...tick_}>{tick}</Circle>
        { children }
      </Relative>
      <Text {...text_} />
    </Wrap>
  )
}