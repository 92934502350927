import { useState } from 'react'
import { Icon }            from 'assets/icons_'
import styled              from 'styled-components/macro'
import { BREAK_ }          from 'constants'
import { Portal }          from 'components/Portal/Portal'
import { Backdrop }        from 'styles/styles.backdrop'
import { AnimatePresence } from 'framer-motion'
import { useBreak }        from 'hooks/useBreak'
import { motion }          from 'framer-motion'
import { ColorLegend }     from 'components/ColorLegend/ColorLegend'
import { DesktopColorLegend }     from 'components/ColorLegend/DesktopColorLegend'

export const Status = ({percent}) => {

  const isMobile = useBreak('md_dn')

  const [state, setState] = useState(false)

  const animation = {
    initial    : { opacity:0 },
    animate    : { opacity:1 },
    exit       : { opacity:0 },
    transition : { duration:0.5, ease:'linear' },
  }


  return (
    percent == 100 &&
    <>
      <Wrap as={motion.div} {...animation} onClick={()=> isMobile && setState(!state)} onMouseEnter={() => !isMobile && setState(true)}
        onMouseLeave={() => !isMobile && setState(false)} >
        <Icon icon='info' size={ isMobile ? 20 : 25 } color={clr?.icons}/>
        Status
      </Wrap>
      { 
        isMobile ?
          <Portal id={'StatusColorLegend'}>
            <AnimatePresence>
              {
                state && (
                  <>
                    <Backdrop onClick={()=>setState(false)}/>
                    <ColorLegend/>
                  </>
                )
              }
            </AnimatePresence>
        </Portal>
        : 
        <AnimatePresence>
          {
            state && (
              <>
                <DesktopColorLegend />
              </>
            )
          }
        </AnimatePresence>
      }
    </>
  )

}

const Wrap = styled.div`
  position: absolute;
  top: calc(100% - 205px);
  left: 40px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 15px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  line-height: 1;
  ${BREAK_.md_dn} {
    font-size: 17px;
    left: 20px;
    top: calc(100% - 140px);
  }
`