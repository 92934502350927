import { getFocus, useAtom, useGet } from 'state/jotai'
import { vis_unitVideo } from 'state/store.global'
import { CloseIcon, Container } from './VideoPortal.style'
import { _project } from 'state/store.projects'
import { useActiveUnit } from 'components/Unit/hooks'
import Video from 'components/Video/Video'
import { Box, IconButton, Modal } from '@mui/material'
import { Icon } from 'components/Icons'
import { Swiper } from 'swiper/react'
import { SwiperSlide } from 'swiper/react'
import { useState } from 'react'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import styled from 'styled-components'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1444,
  maxWidth: { md: "80%", xs: "100%" },
  boxShadow: "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
  outline: "none"
};

export const VideoPortal = () => {
  const [videoStart, setVideoStart] = useAtom(vis_unitVideo)
  const unit        = useActiveUnit();
  const videoURL       = unit.getVideos();
 
  const [swiper, setSwiper] = useState(null);

  const handleClose = () => setVideoStart({open: false, inital_video: 0, singleVideo: false});

  const handlePrev = () => {
    swiper.slidePrev();
  };

  const handleNext = () => {
    swiper.slideNext();
  };

  return (
    <>
    <Modal open={videoStart.open} onClose={handleClose}>
        <Box sx={style}>
          <IconButton
            onClick={handleClose}
            size="small"
            sx={{
              position: "absolute",
              right: 20,
              top: 20,
              color: "#000000",
              backgroundColor: "rgba(255,255,255,0.8)",
              zIndex: 5,
              height: "30px",
              width: "30px",
              '&:hover': {
                // Set the styles for hover state to be the same as default state
                color: "#000000",
                backgroundColor: "rgba(255,255,255,0.8)",
              }
            }}
          >
            <Icon icon="close" fontSize="inherit" />
          </IconButton>
          <Box
            sx={{
              display: "block",
            }}
          />
           <Swiper
            spaceBetween={50}
            slidesPerView={1}
            onSwiper={setSwiper}
            initialSlide={videoStart?.initial_value}
            // onSlideChange={() => console.log('slide change')}
          >
            {
              videoURL?.map((url, i) => {
                return (
                  <SwiperSlide key={i}><Video src={url} aspectRatio={'5/3'} /></SwiperSlide>
                )
              })
            }
            { videoURL?.length > 1 && !videoStart.singleVideo && <>
              <Arrow left onClick={handlePrev}>
                <Icon icon="arrow-left" sx={{ color: "#ffffff", fontSize: 20 }} />
              </Arrow>
              <Arrow right onClick={handleNext}>
                <Icon icon="arrow-right" sx={{ color: "#ffffff", fontSize: 20 }} />
              </Arrow>
            </>}
          </Swiper>
        </Box>
      </Modal>
    </>
  )
}

// STYLES

const Arrow = styled(Box)(({ left, right }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  left: left ? 0 : "unset",
  right: right ? 0 : "unset",
  width: 40,
  height: 40,
  backgroundColor: "#000000bf",
  zIndex: 1000,
  cursor: "pointer",
  "& > span": {
    height: 20,
  },
}));