import { Box } from "@mui/material";
import React from "react";
import {
  Buttons,
  DrawerCTA,
  Gallery,
  Highlights,
  Price,
  TopDrawer,
  useActiveUnit,
} from ".";
import { useUnitHighlight } from "./hooks";
import OpenHouse from "./OpenHouse";
import { getFocus, useAtom, useGet } from "state/jotai";
import { get } from "components/Favorites/Favorites.Utilities";
import {
  _drawer,
  active_project,
  drawer_animation_done,
  vis_unitVideo,
} from "state/store.global";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import { useBreak } from "hooks/useBreak";
import { MoveDate } from "components/List/Card/Card.Content.style";
import { _project } from "state/store.projects";

function UnitDrawer() {
  const unit = useActiveUnit();

  const sID = unit?.data?.Data?.UnitStatusID?.Value;
  const highlights = useUnitHighlight(unit);
  const unitId = parseInt(unit.getId());
  const status = unit.getStatus();
  const address = unit.getAddress();
  const moveindate = unit?.getMoveInDate();
  const moveindateparts = moveindate.split("-"); // Split the string into an array ["2023", "08", "01"]
  // console.log(unit,"sksksk")
  const formattedDate = `${moveindateparts[2]}-${moveindateparts[1]}-${moveindateparts[0]}`; // Rearrange the parts
  const townAndZipcode = unit.getTownZipCode();
  const images = unit.getImages();
  const isRent = unit.isRent();
  const price = unit.getPrice();

  const isMobile = useBreak("md_up");
  const isDrawerAnimating = useAtom(drawer_animation_done)[0];
  const drawer = useAtom(_drawer)[0];
  const isDetail = drawer.detail;
  const thumbnails = isDetail ? unit.getThumb() : [];

  const setVideoStart = useAtom(vis_unitVideo)[1];
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  const todaydate = new Date().getTime();

  const [day, month, year] = formattedDate.split("-").map(Number);
  const availableDateObj = new Date(year, month - 1, day);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        //pb: 5,
        backgroundColor: "#ffffff",
        overflowY: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": { display: "none" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <TopDrawer
          availableDate={formattedDate}
          address={`${address}, ${townAndZipcode}`}
          statusText={status.label}
          statusId={status.id}
          backgroundColor={status.color}
          textColor="#ffffff"
        />

        {/* { isDrawerAnimating && <Gallery Unit={unit} images={images} UnitDrawer onClick={setVideoStart} /> } */}
        <Gallery
          Unit={unit}
          images={images}
          thumbImg={thumbnails}
          UnitDrawer
          onClick={setVideoStart}
          height={isMobile ? "294px" : "321px"}
        />

        <Buttons unitId={unitId} />

        <OpenHouse />

        <Box
          sx={{
            mb: { md: 3, xs: 2 },
            mt: { md: 5, xs: 2 },
            textAlign: "center",
          }}
        >
          <Price
            label={
              availableDateObj <= todaydate ?("") :
              
              buttonsLabel?.filter(
                (btn) => btn.UILabelProperty === "AvailableDatePrefix"
              )[0]?.Label
            }
          
            value= {sID === 10 && moveindate ? (
              availableDateObj <= todaydate ? (
                <p style={{ display: 'inline',}}>
                 {`${buttonsLabel?.filter(btn => btn.UILabelProperty === "AvailableTagTitle")[0]?.Label}`}
                </p>
              ) : (
                <p style={{ display: 'inline',  }}>
                  {formattedDate.split('-').join('.')}
                </p>
              )
            ) : null}

          />
         
          {/* </MoveDate> */}
        </Box>
        <Box sx={{ px: 2.5, mt: { md: 3.5, xs: 2 } }}>
          <Box sx={{ mb: { md: 3.5, xs: 2 }, mx: "auto", maxWidth: 350 , color:"#3e342a"}}>
            <Highlights items={highlights} />
          </Box>

          <Box
            sx={{
              mb: { md: 5.5, xs: 2 },
              mt: { md: 5, xs: 2 },
              textAlign: "center",
            }}
          >
            <Price
              label={
                isRent
                  ? `${get(unit.data, "LejelejlighedsText")}`
                  : "Kontatpris"
              }
              value={price}
            />
          </Box>

          <DrawerCTA unit={unit} />
        </Box>
      </Box>
      <PoweredBy />
    </Box>
  );
}

export default UnitDrawer;
