import { Card, Low, Status, Img, Separator, CTA, MoveDate } from 'components/List/Grid/Grid.Content.style'

import { Text }      from 'components/List/Card/Card.Text'
import { get, getMoveInDate }       from 'components/List/List.Utilities'

import { Icon }      from 'assets/Icons_'
import { getFocus, useAtom, useGet }   from 'state/jotai'
import { active_project, favorites } from 'state/store.global'
import { compares }  from 'state/store.global'
import { FLOORPLAN } from 'api/api'
import { _project } from 'state/store.projects'
import { ImgWrapper } from 'components/ImgContainer/ImgWrapper'

export const Content = ({unit,thumbnail,color}) => {
  
  const likes    = useAtom(favorites)[0]
  const setLikes = useAtom(favorites)[1]
  const comps    = useAtom(compares)[0]
  const setComps = useAtom(compares)[1]
  const sID      = unit.Data.UnitStatusID.Value
  
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const compare    = { icon:'compare2'   , color:clr?.buttons_text, size:28 }
  const compare_   = { icon:'compare2'   , color:'gray'    , size:28 }
  const heartempty = { icon:'heart_empty', color:clr?.buttons_text, size:28 }
  const heartfull  = { icon:'heart_full' , color:'#863E30' , size:28 }

  const isLiked = likes.includes(unit.UnitID)
  const isComps = comps.includes(unit.UnitID)

  const moveindate = getMoveInDate(unit, "Indflytningsdato");
  
  const moveindateparts = moveindate?.split("-"); // Split the string into an array ["2023", "08", "01"]
  // console.log(moveindateparts,"sksksk")
  const formattedDate = `${moveindateparts?.[2]}-${moveindateparts?.[1]}-${moveindateparts?.[0]}`; // Rearrange the parts

  const todaydate = new Date().getTime();

  
  const [day, month, year] = formattedDate.split('-').map(Number);
  const availableDateObj = new Date(year, month - 1, day);

  const toggleLike = (e) => {
    if ( sID == 40 ) { return }
    e.stopPropagation()
    const add = [...likes,unit.UnitID]
    const rem = [...likes].filter( i => i !== unit.UnitID )
    setLikes( isLiked ? rem : add )
  }

  const toggleComp = (e) => {
    if ( sID == 40 ) { return }
    e.stopPropagation()
    const add = [...comps,unit.UnitID]
    const rem = [...comps].filter( i => i !== unit.UnitID )
    const max = comps.length == 3
    setComps( isComps ? rem : ( max ? comps : add ) )
  }

  return (
    <>      
    <ImgWrapper img={thumbnail} alt={FLOORPLAN}/>
      {/* <Img src={thumbnail} alt={FLOORPLAN} loading='lazy'/> */}
      <div style={{height:5}}/>
      <Card>
        <Text unit={unit} cardType={"grid"} />
        <div style={{height:0}}/>
        <Separator color={color}/>
        <Low>
        { unit.Data.UnitStatusID.Value !== 40 && <>
            <CTA onClick={toggleComp}>
              <Icon {... isComps ? compare_ : compare } count={ isComps ? comps.indexOf(unit.UnitID)+1 : 0}/>
              { isComps ? `${ buttonsLabel?.filter( btn => btn.UILabelProperty === 'Valgt')[0]?.Label }` : `${ buttonsLabel?.filter( btn => btn.UILabelProperty === 'Sammenlign')[0]?.Label }` }
            </CTA>
            <CTA onClick={toggleLike}>
              <Icon {...isLiked ? heartfull : heartempty}/>
              { isLiked ? `${ buttonsLabel?.filter( btn => btn.UILabelProperty === 'DinFavorit')[0]?.Label }` : `${ buttonsLabel?.filter( btn => btn.UILabelProperty === 'GemBolig')[0]?.Label }` }
            </CTA>
        </> }
        </Low>
        <Status color={ get(unit,'UnitColor') }>{ get(unit,'UnitStatusName') }</Status>
          <MoveDate color={get(unit,'UnitColor')}>
            {sID === 10 && moveindate ? (
              availableDateObj <= todaydate ? (
                <p style={{ display: 'inline', fontSize: 16, opacity: 0.7 }}>
                  {`(${buttonsLabel?.filter(btn => btn.UILabelProperty === "AvailableTagTitle")[0]?.Label})`}
                </p>
              ) : (
                <p style={{ display: 'inline', fontSize: 16, opacity: 0.7 }}>
                  ({buttonsLabel?.filter(btn => btn.UILabelProperty === "AvailableDatePrefix")[0]?.Label} {formattedDate.split('-').join('.')})
                </p>
              )
            ) : null}
          </MoveDate>
      </Card>
    </>
  )
}