import { Box, Grid, Typography } from "@mui/material";
import { STATICS } from "api/api";
import React from "react";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";

function Footer() {
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  const contactData  = getFocus(_project, 'ContactProperties')?.[pin]?.[pid] ?? []

  return (
    <div>
      <Box
        component="img"
        src={`${STATICS}aarhus.jpg`}
        sx={{ width: "100%", height: { lg: 504, xs: 260 }, objectFit: "cover" }}
      />

      <Box
        sx={{
          mt: 4,
          pb: 55,
          px: { md: 5, xs: 4 },
          py: { md: 4, xs: 2 },
          lineHeight: 1.4,
        }}
      >
        <Box
          sx={{
            fontWeight: 700,
            fontSize: 20,
            mb: 1,
          }}
        >
          { buttonsLabel?.filter( btn => btn.UILabelProperty === 'SidefodKontaktOverskrift')[0]?.Label }
        </Box>

          { contactData?.map((item, i) => {
            return (
              <Grid container sx={{ mb: 5 }} spacing={3} key={i}>
              <Grid item lg={6} xs={12} mb={1}>
                <Box>
                  { item?.Name } <br />
                  { item?.AddressLine1 } <br />
                  { item?.AddressLine2 } <br />
                  { item?.Phone } <br />
                  <Typography
                    component="a"
                    href={`mailto: ${ item.Email }`}
                  >
                    { item?.Email }
                  </Typography>
                </Box>
              </Grid>
    
              <Grid
                item
                lg={6}
                xs={12}
                sx={{
                  textAlign: { lg: "center", xs: "left" },
                  pt: '0 !important',
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "start", lg: "center" }
                }}
              >
                <Box sx={{ maxWidth: { xs: "130px", lg: "180px"}}}>
                  <Box component="img" src={`${STATICS}${ item?.ImageUrl }`} />
                </Box>
              </Grid>
            </Grid>
            )
          })}
       
        {/* <div style={{height:80}}/> */}
      </Box>
    </div>
  );
}

export default Footer;
