import styled from 'styled-components/macro'
import { MENU } from 'constants'

export const Menu = styled.div`
  height: 100%;
  width:100px;
  background-color: ${({ clr }) => clr.primary};
  color: ${({ clr }) => clr.primary_text};
  display: flex;
  flex-direction: column;
  a { text-decoration: none; }
  position: fixed;
  left:0;
  top:0;
  z-index: 3;
  /* border:10px solid orange; */
`
export const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index:5;
  /* border:5px solid green; */
`

export const LogoWrap = styled.div`
  height:60px;
  display:flex;
  align-items:center;
  justify-content:center;
  margin-top: 77px;
   z-index: 10;
  /* border:1px solid black; */
`