import { MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const DropdownItem = ({ children, selected,padding, ...props }) => {
  return (
    <MenuItem
      {...props}
      sx={{
        padding: padding,
        color: selected ? "primary.main" : "inherit",
        backgroundColor: selected ? "rgba(217, 217, 217, 0.35)" : "none",
      }}
    >
      {children}
    </MenuItem>
  );
};

DropdownItem.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.bool,
};

DropdownItem.defaultProps = {};

export default DropdownItem;
