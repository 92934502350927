import { Box, Typography, } from "@mui/material";
import ContactForm from "components/Contact/ContactForm";
import PropTypes from "prop-types";
import React from "react";
import Unit from "./Unit.model";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { useBreak } from "hooks/useBreak";

function SignUpForm({ unit }) {
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
const isDesktop       = useBreak('md_up')
  return (
    <Box id="unit_page_contact_form" sx={{ pt: 7, pb: 5, px: { md: 4, xs: 2.5 }, }} >
      <Box sx={{ maxWidth: 525, mx: "auto", }} >
        <ContactForm grid unit={unit}>
          <Box sx={{ maxWidth: 320, mb: 4, }} >
            {/* <Typography variant="h3" sx={{ fontSize: "1.25rem", fontWeight: 700, lineHeight: 1.25, mb: 3, }} >
              PREMIERE ÅBENT HUS
            </Typography> */}
            <Box sx={{ fontWeight: 700, lineHeight: 1.4,color:isDesktop ? "#f4f1e0" : "#3e342a" }} >
              { buttonsLabel?.filter( btn => btn.UILabelProperty === 'SidefodformularOverskrift')[0]?.Label }
            </Box>
            <br />
            <Box sx={{ fontWeight: 400, lineHeight: 1.4, fontSize: 15,color:isDesktop ? "#f4f1e0" : "#3e342a"}} >
            { buttonsLabel?.filter( btn => btn.UILabelProperty === 'SidefodformularUnderoverskrift')[0]?.Label }
            </Box>
          </Box>
        </ContactForm>
      </Box>
    </Box>
  );
}

SignUpForm.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

export default SignUpForm;
