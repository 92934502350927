import styled from 'styled-components/macro'
import { sb } from 'styles/styles.scrollbar'
import { BREAK_ } from 'constants'

export const Box = styled.div`
  gap: 5px;
  cursor: pointer;
  z-index: 5;
  align-items: center;
  display: flex;
  /* border: 1px solid black; */
  font-size: 1rem;
`

export const Option = styled.div`
  padding: 4px;
  width: 250px;
  height: 22px;
  font-size: 1rem;
  font-weight: 400;
  background: ${({ active }) =>
    active ? `${clr.buttons}ff` : `${clr.buttons}1a`};
  color: ${({ active }) => (active ? '#fff' : '#000')};
  text-align: center;
  line-height: 1;
  border-radius: 9px;
`

export const Separator = styled.div`
  width: 30px;
  min-height: 2px;
  background: #ffffff;
  border-radius: 11px;
  margin: 5px 0;
`