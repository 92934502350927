import moment from 'moment';
import { useGet } from 'state/jotai';
import { current_language } from 'state/store.global'
import 'moment/dist/locale/da';


const Months = [
  'Januar',
  'Februar',
  'Marts',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'December',
]

const Days = [
  'Søndag',
  'Mandag',
  'Tirsdag',
  'Onsdag',
  'Torsdag',
  'Fredag'
]

export const WDay = (x) => {
  const date = new Date(x)
  
  // return Days[date.getDay()]
  return moment(date).format('dddd')
}

export const MDay = (x) => {
  const date = new Date(x)
  return date.getDate()
}

export const Month = (x) => {
  const date = new Date(x)
  const language = useGet(current_language)


  moment.locale(language);
  // return Months[date.getMonth()]
  return moment(date).format('MMMM');
}
